<template>
    <v-card-text>
        <crud
            label="My Templates"
            model="templates"
            :headers="headers"
            :conditions="[{client_id:{eq:$store.state.user.payload.id}}, {deleted:{'eq':'0'}}]"
            :initialValues="{client_id:$store.state.user.payload.id}"
            :canSearch="true"
            :key="rerender"
        >
            <template v-slot:[`item.columns`]="obj">
                <span v-for="(v, i) in JSON.parse(obj.item.columns)" :key="i">{{$store.state.columns.getText(v)}}; </span>
            </template>
            <template v-slot:[`item.visual`]="obj">
                <span v-for="(v, i) in JSON.parse(obj.item.visual)" :key="i">{{$store.state.columns.getVizText(v)}}; </span>
            </template>
            <template v-slot:[`item.is_default`]="obj">
                <v-btn small icon @click="toggleItem(obj.item.id, 'is_default', 0)" v-if="obj.item.is_default=='1'"><v-icon color="success" small>mdi-checkbox-marked-circle</v-icon></v-btn>
                <v-btn small icon @click="toggleItem(obj.item.id, 'is_default', 1)" v-else><v-icon small>mdi-close-circle</v-icon></v-btn>
            </template>
            <template v-slot:[`item.load`]="obj">
                <v-btn color="primary" tile small @click="$emit('loadtemplate', obj.item.id, obj.item.columns, obj.item.visual)"><v-icon small>mdi-table-arrow-down</v-icon> Load</v-btn>
            </template>
        </crud>
    </v-card-text>
</template>

<script>
import crud from "../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Template Name", value:"name", mandatory: true, fieldOptions: {"dense":true}, searchable:true, sm: 12},
                {text:"Table Columns", value:"columns", mandatory: true, slot:true, type:"checkboxlist", items: this.$store.state.columns.getAll(), searchable:true, sm: 12},
                {text:"Visual Data", value:"visual", mandatory: false, slot:true, type:"checkboxlist", items: this.$store.state.columns.getVisual(), searchable:true, sm: 12},
                {text:"Default", value:"is_default", mandatory: true, type:"switch", fieldOptions: {"dense":true, "inset":true, "false-value": "0", "true-value": "1"}, slot:true},
                {text: "Load", value: "load", query: false, slot:true}
            ],
            rerender: 0,
        }
    },
    methods: {
        toggleItem(id, name, newVal) {
            this.axios.post(this.ApiEndpoint, {
                operation: 'update',
                table: 'templates',
                data: [{'id': id, [name]: newVal}]
            }).then(() => {
                this.rerender++;
            })
        }
    },
}
</script>