<template>
    <v-container fluid>
        <v-row dense v-if="canSearch">
            <v-col cols="sm-4 offset-sm-8">
                <v-text-field
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    hide-details
                    single-line
                    label="Search"
                    @input="searchItems"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row dense :class="hasError ? 'error--text' : ''">
            <slot name="label">
                <span>{{label}}</span>
            </slot>
        </v-row>
        <v-row dense v-if="hasError">
            <slot name="message"><span class="error--text caption">{{errorBucket[0]}}</span></slot>
        </v-row>
        <v-row dense>
            <v-col v-for="(item, index) of filtered" :key="index" cols="auto">
                <v-checkbox v-if="item" dense v-model="selectedItems" :label="item[itemText]" :id="index+''" :value="item[itemValue]" @change="onChange" multiple v-bind="$attrs"></v-checkbox>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        items: {type: Array, required: true},
        value: {type: String, required: true, default: () => ""},
        itemText: {type: String, default: () => 'text'},
        itemValue: {type: String, default: () => 'value'},
        canSearch: {type: Boolean, default: () => false},
        label: {type: String},
        rules: {type: Array, required: false},
    },
    inject: {
        'form': {
            default: false,
        }
    },
    data() {
        return {
            selectedItems: [],
            errorBucket: [],
            filtered: JSON.parse(JSON.stringify(this.items)),
            searchText: "",
            valid: false,
            isResetting: false,
        }
    },
    methods: {
        onChange() {
            this.$emit("input", JSON.stringify(this.selectedItems));
            this.form && this.form.validate();
        },
        searchItems() {
            if (this.searchText.length > 2) {
                this.filtered.forEach((item, index) => {
                    if(item[this.itemText].toLowerCase().indexOf(this.searchText.toLowerCase()) == -1) delete this.filtered[index];
                });
            }
            else {
                this.filtered = JSON.parse(JSON.stringify(this.items));
            }
        },
        validate() {
            const errorBucket = [];
            let value = this.selectedItems;

            if(this.rules) {
                for (let index = 0; index < this.rules.length; index++) {
                    const rule = this.rules[index];
                    const valid = typeof rule === 'function' ? rule(value) : rule;

                    if (valid === false || typeof valid === 'string') {
                        errorBucket.push(valid || '');
                    } else if (typeof valid !== 'boolean') {
                        console.Error(`Rules should return a string or boolean, received '${typeof valid}' instead`, this);
                    }
                }
            }

            this.errorBucket = errorBucket;
            this.valid = errorBucket.length === 0;

            return this.valid;
        },
        resetValidation() {
            this.isResetting = true;
        },
    },
    computed: {
        hasError() {
            return this.errorBucket.length > 0;
        },
    },
    watch: {
        value: {
            handler(n) {
                if(n) {
                    this.selectedItems = JSON.parse(this.value);
                }
            },
            immediate: true
        },
    },
    created() {
        this.form && this.form.register(this);
    },
    beforeDestroy () {
        this.form && this.form.unregister(this);
        this.selectedItems = [];
    },
    mounted() {
        //this.filtered = JSON.parse(JSON.stringify(this.items));
    },
}
</script>