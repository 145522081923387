<template>
    <v-container fluid>
        <v-tabs v-model="tab" grow>
            <v-tab>Data Mapping</v-tab>
            <v-tab>Data Display</v-tab>
            <v-tab>Certificate</v-tab>

            <!-- Data Mapping -->
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card class="mt-6">
                        <v-row>
                            <v-col cols="12">
                                <v-switch inset v-model="settings.merge_notes" label="Merge Notes" hint="Merges Notes1, Notes2 and Notes3 from PAT device into a single field" persistent-hint></v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="!settings.merge_notes" dense>
                            <p class="body-2 ml-3 mt-3">Map user comments into specific fields:</p>
                            <v-col cols="12">
                                <v-autocomplete dense v-model="settings.notes1" label="Notes 1" :items="$store.state.columns.getAll()" item-value="value" item-text="text"></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete dense v-model="settings.notes2" label="Notes 2" :items="$store.state.columns.getAll()" item-value="value" item-text="text"></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete dense v-model="settings.notes3" label="Notes 3" :items="$store.state.columns.getAll()" item-value="value" item-text="text"></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete dense v-model="settings.notes4" label="Notes 4" :items="$store.state.columns.getAll()" item-value="value" item-text="text"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-switch v-model="settings.map_iec" label="Map IEC to Earth" inset class="mt-4"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="settings.abbrv" label="Abbreviations Match:" row>
                                    <v-radio value="word" label="Single Word"></v-radio>
                                    <v-radio value="cell" label="Whole Cell"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>

                <!-- Display -->
                <v-tab-item>
                    <v-card class="pt-4">
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="settings.items_per_page" label="Number of Rows in Tables:" row>
                                    <v-radio :value="75" label="75"></v-radio>
                                    <v-radio :value="150" label="150"></v-radio>
                                    <v-radio :value="-1" label="All"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="settings.date_format" label="Date Format:" row>
                                    <v-radio value="en-GB" label="dd/mm/yyyy"></v-radio>
                                    <v-radio value="en-US" label="mm/dd/yyyy"></v-radio>
                                    <v-radio value="iso" label="yyyy-mm-dd"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>

                <!-- Certificate -->
                <v-tab-item>
                    <v-card class="pt-4">
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="settings.cert_date" label="Certificate Date:" row>
                                    <v-radio value="validto" label="Valid To Date"></v-radio>
                                    <v-radio value="tested" label="Tested Date"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12">
                                <v-switch inset v-model="settings.cert_sort_data" label="Auto-sort data by No field"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>            
        </v-tabs>
            <v-row>
                <v-col cols="12">
                    <v-progress-linear indeterminate class="ma-1" :active="!!loading"></v-progress-linear>
                    <v-alert text :color="alert.color" v-model="alert.show" tile dismissible>{{alert.text}}</v-alert>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12">
                    <v-btn tile color="primary" @click="savePreferences()">Save Preferences</v-btn>
                </v-col>
            </v-row>
       
    </v-container>
</template>

<script>
export default {
    components: {

    },
    props: {
        
    },
    data() {
        return {
            tab: 0,
            settings: {
                merge_notes: 0,
                notes1: null,
                notes2: null,
                notes3: null,
                notes4: null,
                map_iec: null,
                items_per_page: 75,
                date_format: null,
                abbrv: 'cell',
                cert_date: 'validto',
                cert_sort_data: 1,
            },
            alert: {
                show: false,
                text: null,
                color: null,
            },
            loading: false,
            rowId: 0,
        }
    },
    methods: {
        savePreferences() {
            this.loading = true;
            let app = this;

            if(this.rowId > 0) {
                this.axios.post(this.ApiEndpoint, {
                    table: "settings",
                    operation: "update",
                    fields: ["client_id", "preferences"],
                    data: [{"id": app.rowId, "client_id": app.$store.state.user.payload.id, "preferences": JSON.stringify(app.settings)}],
                }).then(() => {
                    app.loading = false;
                    app.alert.show = true;
                    app.alert.text = "Preferences saved successfully."
                    app.alert.color = "green";

                    app.$store.dispatch('updatePreferences', app.settings);
                })
            }
            else {
                this.axios.post(this.ApiEndpoint, {
                    table: "settings",
                    operation: "insert",
                    data: [{"client_id": app.$store.state.user.payload.id, "preferences": JSON.stringify(app.settings)}],
                }).then((res) => {
                    app.loading = false;
                    if(res.data.inserted.length > 0) {
                        app.alert.show = true;
                        app.alert.text = "Preferences saved successfully."
                        app.alert.color = "green";
                        app.rowId = res.data.inserted[0];

                        app.$store.dispatch('updatePreferences', app.settings);
                    }
                })
            }
        },
        getPreferences() {
            let app = this;
            this.loading = true;

            this.axios.post(this.ApiEndpoint, {
            table: "settings",
            operation: "select",
            fields: ["id", "preferences"],
            where: [{client_id:{eq: app.$store.state.user.payload.id}}]
            }).then(res => {
                if(res.data.data.length > 0) {
                    app.settings = JSON.parse(res.data.data[0]['preferences']);
                    app.rowId = res.data.data[0]["id"];
                }
            }).finally(() => {
                app.loading = false;
            });
        },
    },
    mounted() {
        this.getPreferences();
    }
}
</script>