import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import columns from './columns'
import countries from './countries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{isAuthenticated:false, token:null, loading:true, payload:null, preferences:null, timeout:0},
    columns: columns,
    countries: countries.items,
  },
  mutations: {
    initializeStore(state, {token,exp,preferences}) {
      if(token) {
          let payload = parseJwt(token);
          if(payload instanceof Object) state.user.payload = payload;
          state.user.token = token;
          state.user.timeout = exp;
          state.user.preferences = JSON.parse(preferences);
          if(exp > Date.now()) state.user.isAuthenticated = true;
      }
  
      state.user.loading = false;
    },
    logIn: (state, {token, payload, exp, preferences}) => {
      if (typeof localStorage !== 'undefined') {
          localStorage.setItem('pat-token', token);
          localStorage.setItem('pat-token-exp', exp);
          localStorage.setItem('pat-pref', preferences);
      }
      if(!(payload instanceof Object)) payload = null;
      state.user.payload = payload;
      state.user.preferences = JSON.parse(preferences);
      state.user.token = token;
      state.user.isAuthenticated = true;
      state.user.timeout = exp;
      state.user.loading = false;
    },
    logOut: (state) => {
      if (typeof localStorage !== 'undefined') {
          localStorage.removeItem('pat-token');
          localStorage.removeItem('pat-token-exp');
          localStorage.removeItem('pat-pref');
      }

      state.user = {};
      state.user.isAuthenticated = false;
      state.user.loading = false;
      state.user.token = null;
      state.user.timeout = 0;
      state.user.payload = null;
      state.user.preferences = null;

      if (router.currentRoute.path !== '/login') router.push('/login');
    },
    updatePreferences: (state, preferences) => {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('pat-pref', JSON.stringify(preferences));
      }
      state.user.preferences = preferences;
    }
  },
  actions: {
    initializeStore(context) {
      if (typeof localStorage !== 'undefined') {
          let token = localStorage.getItem('pat-token');
          let exp = localStorage.getItem('pat-token-exp');
          let preferences = localStorage.getItem('pat-pref');

          if(exp > Date.now()) {
              setTimeout(() => {
                  context.dispatch('logOut');
              }, exp - Date.now());
          }

          context.commit('initializeStore', {token, exp, preferences});
      }
    },
    logIn(context, {token, preferences}) {
      if(token) {
          let payload = parseJwt(token);
          let exp = new Date(payload.exp*1000).getTime();
          
          setTimeout(() => {
              context.dispatch('logOut');
          }, exp - Date.now());

          context.commit('logIn', {token, payload, exp, preferences});
      }  
    },
    logOut(context) {
        context.commit('logOut');

        if (typeof localStorage !== 'undefined') {
            let int = setInterval(() => {
                let token = localStorage.getItem('pat-token');
                let exp = localStorage.getItem('pat-token-exp');
                if(token && exp > Date.now()) {
                    clearInterval(int);
                    context.dispatch('logIn',token);
                }
            }, 1000);
        }
    },
    updatePreferences(context, preferences) {
      context.commit('updatePreferences', preferences);
    }
  },
  modules: {
  }
});

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
