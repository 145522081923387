export default {
    getText(value) {
        let txt = null;
        this.columns.forEach(item => {
            if(item.value == value) {
                txt = item.text;
            }
        });

        return txt;
    },
    getValue(text) {
        let val = null;
        this.columns.forEach(item => {
            if(item.text == text) {
                val = item.value;
            }
        });

        return val;
    },
    getAll() {
        return this.columns;
    },
    getVisual() {
        return this.visual;
    },
    getVizText(value) {
        let txt = null;
        this.visual.forEach(item => {
            if(item.value == value) {
                txt = item.text;
            }
        });

        return txt;
    },
    columns: [
        {text:"No", value:"id"},
        {text:"Date", value:"date"},
        {text:"Time", value:"time"},
        {text:"Venue", value:"venue"},
        {text:"Location", value:"location"},
        {text:"Tester", value:"user"},
        {text:"Description", value:"description"},
        {text:"Make", value:"make"},
        {text:"Model", value:"model"},
        {text:"Notes", value:"notes"},
        {text:"Visual", value:"visual"},
        {text:"IEC, Ω", value:"iec"},
        {text:"EARTH, Ω", value:"earth"},
        {text:"INS, MΩ", value:"ins"},
        {text:"LKGE, mA", value:"lkge"},
        {text:"SUB LKGE, mA", value:"sublkge"},
        {text:"MW LKGE, mW/cm²", value:"mw"},
        {text:"MAINS, V", value:"mains"},
        {text:"POLARITY", value:"polarity"},
        {text:"RCD, ms", value:"rcd"},
        {text:"P2P, Ω", value:"p2p"},
        {text:"Retest Period", value:"retest"},
        {text: "Visual Retest Period", value: "visual_retest"},
        {text:"Result", value:"result"}
    ],
    visual: [
        {text:"Plug", value: "plug"},
        {text:"Flex", value: "flex"},
        {text:"Enclosure", value:"enclosure"},
        {text:"Environment", value:"environment"},
        {text:"Switch", value:"switch"},
        {text:"Socket", value:"socket"},
        {text:"Voltage", value:"voltage"},
        {text:"Power Rating", value:"power rating"},
        {text:"Fuse Rating", value:"fuse rating"},
    ]
}