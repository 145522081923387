
<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="8">
                <v-text-field outlined label="" dense v-model="name" readonly></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-btn v-bind="$attrs" block @click.stop="uploadFile()">
                    Browse...
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="error!==false || uploading" no-gutters justify="center" align="stretch" class="fill-height">
            <v-col cols="auto" align-self="center">
                <v-alert v-if="error!==false" type="error" dense>{{error}}</v-alert>
                <v-progress-circular v-if="uploading" color="error"  indeterminate>Uploading...</v-progress-circular>
            </v-col>
        </v-row>
        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />
    </v-container>
</template>

<script>
export default {
    props: {
        value: [Number, String],
        ownerId: { type: String, mandatory: true },
        accept: { type: String, default: "*/*" },
        endPoint: { type: String, default: "https://patconnect.com/api/migration.php"},
        placeholder: String,
    },
    data() {
        return {
            uploading: false,
            error: false,
            name: null,
        };
    },
    methods: {
        uploadFile() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.accept = this.accept;
            this.$refs.fileInput.click();
            this.$refs.fileInput.removeEventListener("change", this._uploadFile);
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            const file = e.target.files[0];
            this.name = file.name;

            if(file && this.ownerId) {
                this.uploading = true;
                this.error = false;

                const formData = new FormData();
                formData.append("file", file, file.name);

                this.axios.post(this.endPoint + "?ownerID=" + this.ownerId, formData).then(res => {
                    if (res.data.status == "uploaded") {
                        this.$emit("input", res.data.filename);
                    }
                    else this.error = "Uploading failed!";
                }).catch(res => {
                    this.error = "Uploading failed!";
                    this.debug = res;
                }).finally(() => {
                    this.uploading = false;
                    e.target.value = null;
                });
            }
            else {
                this.error = "Missing Upload Parameter!";
            }
        }
    }
};
</script>