<template>
    <v-container fluid class="fill-height bg-pattern pa-0">
        <v-container fluid class="fill-height bgw">
            <v-card v-resize="resized" elevation="4" width="100%" max-width="500" class="mx-auto">
                <v-card-text class="py-0 px-3">
                    <v-row>
                        <v-col
                            class="login-welcome pa-6 pa-sm-12" cols="12" sm="12"
                        >
                            <v-spacer />
                            <v-img
                                src="../assets/logo.png"
                                max-width="300"
                                class="mx-auto mt-3"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="py-md-12 px-8"
                            cols="12"
                            sm="12"
                            v-if="invalidLogins < 5"
                        >
                            <v-form autocomplete="on" v-model="valid">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            dense
                                            label=""
                                            outlined
                                            class="mb-1 d-none"
                                            name="username"
                                            value=""
                                            autocomplete="username"
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            ref="license"
                                            outlined
                                            label="License Key"
                                            placeholder=" "
                                            autocomplete="current-password"
                                            :type="passFieldType"
                                            name="license"
                                            v-model="license"
                                            :append-icon="appendIcons[passFieldType]"
                                            :disabled="loading"
                                            :rules="[licenseRequired]"
                                            @keydown.enter="license && login()"
                                            @click:append="toggleShowPwd()"
                                            dense
                                            hide-details="auto"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            block
                                            v-on:click="login()"
                                            v-if="!$store.state.user.isAuthenticated"
                                            :disabled="!valid"
                                            color="primary"
                                        >Login</v-btn>
                                        <v-btn
                                            outlined
                                            block
                                            v-on:click="logout()"
                                            v-else
                                            color="#a0bff8"
                                        >Logout</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="error">
                                    <v-col>
                                        <v-alert class="mt-3" type="error">{{error}}</v-alert>
                                    </v-col>
                                </v-row>
                                <v-row v-if="loading">
                                    <v-col>
                                        <v-progress-linear indeterminate></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-col class="py-12 order-last order-sm-first" cols="12" sm="12" v-else>
                            <v-row align="center" class="fill-height">
                                <v-alert
                                    class="pa-4 ma-4"
                                    type="error"
                                    text
                                >Your IP address was blocked because of too many login attempt failures!</v-alert>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-container fluid class="text-caption text-center">
                        <span class="text-grey">Powered by <a class="power" href="https://digitaldevelopments.tech" target="_blank" rel="noopener" title="Visit website" aria-label="Digital Developments Ltd">Digital Developments Ltd</a></span>
                    </v-container>
                </v-card-text>
            </v-card>
        <captcha
            v-if="useCaptcha"
            action="login"
            sitekey="6LdYD3IdAAAAALf0HBh5WrsqH1wxMONZso1IVfZZ"
            @reCAPTCHA3="getToken"
        />
    </v-container>
    </v-container>
</template>
<script>
import captcha from "../components/reCAPTCHAv3.vue";

export default {
    components: {
        captcha,
    },
    data() {
        return {
            landscape: true,
            license: null,
            captcha: null,
            error: null,
            valid: false,
            loading: false,
            invalidLogins: 0,
            useCaptcha: false,
            appendIcons: {'password': 'mdi-eye', 'text': 'mdi-eye-off'},
            passFieldType: 'password',
            licenseRequired: function (val) {
                return !!val || "This is a required field!";
            },
        };
    },
    methods: {
        getToken(t) {
            this.captcha = t;
        },
        resized() {
            this.landscape = window.innerWidth > window.innerHeight;
        },
        toggleShowPwd() {
            this.passFieldType = (this.passFieldType == 'password' ? 'text' : 'password')
        },
        login() {
            this.error = null;
            this.info = null;
            this.loading = true;

            let app = this;
            
            this.axios.post(this.ApiEndpoint+"login.php", {
                'license': this.license,
                'captcha': this.captcha,
            }).then(res => {
                if(res.data.token) {
                    app.$store.dispatch("logIn", res.data);
                    app.$router.push("/");
                }
                else if(res.data.error) {
                    app.error = res.data.error;
                    app.license = null;
                }
                else {
                    app.error = "Invalid license key";
                    app.invalidLogins++;
                    app.license = null;
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        logout() {
            this.$store.dispatch("logOut");
        }
    },
    beforeCreate() {
        this.useCaptcha = process.env.VUE_APP_GOOGLE_CAPTCHA;
    }
}
</script>
<style scoped>
.bg-pattern {
    background: transparent url("/bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.bgw {
    background: rgba(255, 255, 255, 0.9);
}
a.power {
    color: grey;
    text-decoration: none;
}
</style>