<template>
    <Login />
</template>
<script>
import Login from "../components/Login.vue"
export default {
    components: {
        Login,
    },
    data() {
        return {

        }
    }
}
</script>