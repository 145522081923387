<template>
    <div id="g-recaptcha"></div>
</template>
<script>
export default {
    props: {
        sitekey: {type: String, required: true},
        action: {type: String, required: true},
    },
    data() {
        return {

        }
    },
    mounted() {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
        recaptchaScript.async = true;
        recaptchaScript.onload = this.renderWait();
        document.head.appendChild(recaptchaScript);
    },
    methods: {
        recaptchaRender() {
            var app = this;
            window.grecaptcha.ready(function() {
                var widgetId = window.grecaptcha.render('g-recaptcha', {
                    'sitekey': app.sitekey,
                    'badge': 'bottom',
                    'size': 'invisible',
                    'theme': 'light',
                });
                window.grecaptcha.execute(widgetId, {action: this.action}).then(function(token) {
                    app.$emit("reCAPTCHA3", token);
                })
            });
        },
        renderWait() {
            setTimeout(() => {
                if(typeof window.grecaptcha !== "undefined" && typeof window.grecaptcha.render !== "undefined") this.recaptchaRender();
                else this.renderWait();
            });
        }
    }
}
</script>