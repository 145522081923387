<template>
    <v-container fluid>
        <v-progress-linear indeterminate class="ma-2" :active="!!loading"></v-progress-linear>

        <FullCalendar ref="fullcalendar" :options="calendarOptions" class="calendar" />

        <v-dialog v-model="dialog" v-if="dialog" max-width="60vw">
          <v-card>
            <v-toolbar dark dense color="primary" :elevation="0">
              <span>Customer Report</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="dialog=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <Report :report_id="report_id" />
          </v-card>
        </v-dialog>

        <v-dialog v-model="eventDialog" v-if="eventDialog" max-width="30vw">
          <v-card>
            <v-toolbar dark dense color="primary" :elevation="0">
              <span>Event Details</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="eventDialog=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr><th>Location</th><th>Date</th><th>Booked</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-html="eventInfo.event.title"></td><td>{{eventInfo.event.start.toLocaleDateString()}}</td><td><v-icon v-if="eventInfo.event.extendedProps.booked=='1'" color="success">mdi-check-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn small color="primary" @click="viewReport(eventInfo.event.extendedProps.report_id)">View PAT Report</v-btn> <v-btn small dark color="error" @click="removeEvent(eventInfo)">Remove From Calendar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
//import '@fullcalendar/core/vdom' // solves problem with Vite
import '@fullcalendar/core'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import Report from "./Report.vue"
export default {
  components: {
    FullCalendar,
    Report,
  },
  data() {
    return {
      loading: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'print,dayGridMonth,dayGridWeek'
        },
        customButtons: {
          print: {
            text: "Print",
            click: (e) => {
              e.preventDefault();
              this.calendarPrint();
            }
          }
        },
        initialView: 'dayGridMonth',
        showNonCurrentDates: true,
        height: "auto",
        editable: true,
        selectable: true,
        /*
        eventSources: [{
          url: "https://patconnect.com/api/calendar.php",
          method: "POST",
          headers: {
            // Not supported!
            Authorization: "jwt " + localStorage.getItem("pat-token"),
          },
          extraParams: {
            client_id: this.$store.state.user.payload.id,
          },
          textColor:"#000000",
          //color: "#93ff85",
        }],
        */
        events: (info, successCallback) => {
          let start = info.start.getFullYear() + "-" + String(info.start.getMonth() + 1).padStart(2, "0") + "-" + String(info.start.getDate()).padStart(2, "0");
          let end = info.end.getFullYear() + "-" + String(info.end.getMonth() + 1).padStart(2, "0") + "-" + String(info.end.getDate()).padStart(2, "0");
          this.axios.post(this.ApiEndpoint + "calendar.php", {
            "start": start,
            "end": end,
            "client_id": this.$store.state.user.payload.id,
          }).then(response => {
            successCallback(response.data);
          });
        },
        eventContent: function(info) {
          return {html: info.event.title};
        },
        lazyFetching: false,
        loading: (isLoading) => {
          this.loading = isLoading;
        },
        eventClick: (info) => {
          this.showEventDialog(info);
        },
        longPressDelay: 10,
      },

      dialog: false,
      eventDialog: false,
      eventInfo: {},
      customer_id: null,
      report_id: null,
      calendarApi: null,
    }
  },
  methods: {
    showEventDialog(info) {
      this.eventDialog = true;
      this.eventInfo = info;
    },
    calendarPrint() {
      let el = this.$refs.fullcalendar.$el;
      let w = window.open();

      let link = w.document.createElement('link')
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = 'https://patconnect.com/css/fullcalendar.min.css';

      w.document.head.appendChild(link)

      w.document.body.appendChild(el.cloneNode(true));
      w.setTimeout(function() {
        w.window.print();
        w.close();
      }, 1000);

      return false;
    },
    removeEvent(eventInfo) {
      if(confirm("Are you sure you want to remove this event?")) {
        this.axios.post(this.ApiEndpoint, {
            table: "calendar",
            operation: "delete",
            where: [{"id":{"eq": eventInfo.event.id}}]
        }).then(() => {
            eventInfo.event.remove();
            this.eventDialog = false;
        })
      }
    },
    viewReport(report_id) {
      this.report_id = report_id;
      this.dialog = true;
    },
  },
  mounted() {
    //const calendar = this.$refs.fullcalendar.getApi();
  }
}
</script>