<template>
    <v-container fluid>
        <v-card class="pa-4">
            <v-card-text>Quickly and easily transfer your customer data from your existing system to ours by uploading a CSV data file. Your data will be processed within 24-48 hours.</v-card-text>
            
            <FileUpload accept=".csv, .txt" :ownerId="$store.state.user.payload.id" v-model="filename" />

            <v-text-field dense outlined v-model="source" label="Source" hint="What solution is the file coming from, .e.g. SimplyPats, Apollo, Megger, etc."></v-text-field>
            
            <v-btn large outlined block color="primary" :disabled="!source" class="mt-6" @click="SendRequest()">Send</v-btn>

            <v-alert text :color="alert.color" v-model="alert.show" class="rounded-0 mt-6" dismissible>{{alert.text}}</v-alert>
        </v-card>
    </v-container>
</template>

<script>
    import FileUpload from  "../components/FileUpload.vue";

    export default {
        components: {
            FileUpload,
        },
        data() {
            return {
                source: null,
                filename: null,
                alert: {
                    show: false,
                    text: null,
                    color: null
                }
            }
        },
        methods: {
            SendRequest() {
                let app = this;

                this.axios.post(this.ApiEndpoint, {
                    operation: 'insert',
                    table: 'migration',
                    data: [{'client_id': this.$store.state.user.payload.id, 'source': this.source, 'filename': this.filename}]
                }).then(res => {
                    if(res.data.inserted > 0) {
                        app.axios.post(app.ApiEndpoint, {
                            operation: 'insert',
                            table: 'emails',
                            data: [{'recipient': 'nikolay.panev@gmail.com', 'sender': 'PAT Connect', 'subject': 'Data Migration Request', 'content': 'PAT Connect New Data Migration Request: '+res.data.inserted, 'attempts': '0'}]
                        });
                        app.alert.text = "Your request was submitted. Working on it now, check back in 24-48 hours."
                        app.alert.color = 'success';
                        app.alert.show = true;
                    }
                    else {
                        app.alert.text = "There was an error sending your request. Please contact support."
                        app.alert.color = 'error';
                        app.alert.show = true;
                    }
                })
            }
        }
    }
</script>