<template>
    <div ref="table" v-on="$listeners"></div>
</template>
<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables';

export default {
    props: {
        options: {type: Object, mandatory: true},
    },
    data() {
        return {
            tabulator: null,
        }
    },
    methods: {
        getInstance() {
            return this.tabulator;
        },
    },
    mounted() {
        this.tabulator = new Tabulator(this.$refs.table, this.options);
    },
}
</script>
