<template>
    <v-card-text>
        <crud
            label="Saved Reports"
            model="reports"
            :headers="headers"
            :conditions="[{id: {eq: report_id}}, {client_id:{eq:$store.state.user.payload.id}}, {deleted:{'eq':'0'}}]"
            :initialValues="{client_id:$store.state.user.payload.id}"
            :canAdd="false"
            :canEdit="false"
            :canSearch="false"
            :key="rerender"
        >
            <template v-slot:[`item.input_file`]="obj">
                <v-btn small icon v-for="(v, i) in JSON.parse(obj.item.input_file)" :key="i" :href="v.url" target="_blank"><v-icon>mdi-file</v-icon></v-btn>
            </template>
            <template v-slot:[`item.output_file`]="obj">
                <v-btn small icon v-for="(v, i) in JSON.parse(obj.item.output_file)" :key="i" :href="v.url" target="_blank"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
            </template>
            <template v-slot:[`item.reminders`]="obj">
                <v-btn small icon @click="toggleItem(obj.item.id, 'reminders', 0)" v-if="obj.item.reminders=='1'"><v-icon small color="success">mdi-check-circle</v-icon></v-btn>
                <v-btn small icon @click="toggleItem(obj.item.id, 'reminders', 1)" v-else><v-icon small>mdi-close-circle</v-icon></v-btn>
            </template>
            <template v-slot:[`item.next_test_booked`]="obj">
                <v-btn small icon @click="toggleItem(obj.item.id, 'next_test_booked', 0)" v-if="obj.item.next_test_booked=='1'"><v-icon small color="success">mdi-check-circle</v-icon></v-btn>
                <v-btn small icon @click="toggleItem(obj.item.id, 'next_test_booked', 1)" v-else><v-icon small>mdi-close-circle</v-icon></v-btn>
            </template>
            <template v-slot:[`item.created`]="obj">
               {{new Date(obj.item.created).toLocaleDateString()}}
            </template>
            <template v-slot:[`item.valid_to`]="obj">
               {{new Date(obj.item.valid_to).toLocaleDateString()}}
            </template>
        </crud>
    </v-card-text>
</template>
<script>
import crud from "../components/CRUD.vue"
export default {
    props: {
        report_id: {type: String, required: true},
    },
    components: {
        crud,
    },
    data() {
        return {
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Customer", value:"locations.name", modelId: "location_id", modelFields: {"text":"name", "value":"id"}, mandatory: true},
                {text:"Report Date", value:"created", slot: true},
                {text:"Valid To", value:"valid_to", slot: true},
                {text:"Next Test Booked", value:"next_test_booked", type: "switch", slot: true},
                {text:"Reminders", value:"reminders", type: "switch", slot: true},
                {text:"PAT File", value:"input_file", mandatory: true, slot:true},
                {text:"PDF Report", value:"output_file", mandatory: true, slot:true},
            ],
            rerender: 0,
        }
    },
    methods: {
        toggleItem(id, name, newVal) {
            this.axios.post(this.ApiEndpoint, {
                operation: 'update',
                table: 'reports',
                data: [{'id': id, [name]: newVal}]
            }).then(() => {
                this.rerender++;
            })
        }
    }
}
</script>