
<template>
    <v-tooltip bottom :disabled="!uploadOnClick">
        <template v-slot:activator="{ on }">
            <v-img v-bind="$attrs" v-on="on" :src="thisSrc" @click.stop="uploadOnClick ? uploadFile() : null" :style="'cursor:'+(uploadOnClick ? 'pointer' : 'default')">
                <v-row v-if="error!==false || uploading" no-gutters justify="center" align="stretch" class="fill-height">
                    <v-col cols="auto" align-self="center">
                        <v-alert v-if="error!==false" type="error" dense>{{error}}</v-alert>
                        <v-progress-circular v-if="uploading" color="error"  indeterminate>Uploading...</v-progress-circular>
                    </v-col>
                </v-row>
                <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />
            </v-img>
        </template>
         <span>Click to change logo</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        value: [Number, String],
        ownerId: { type: String, mandatory: true },
        accept: { type: String, default: "image/*" },
        endPoint: { type: String, default: "https://patconnect.com/api/upload.php"},
        placeholder: String,
        uploadOnClick: {type:Boolean, default:true}
    },
    data() {
        return {
            uploading: false,
            error: false,
            thisSrc: undefined
        };
    },
    watch: {
        placeholder: {
            handler(n) {
                if (!this.thisSrc) {
                    this.thisSrc = n;
                }
            },
            immediate: true
        },
        value: {
            handler(n) {
                if (n) {
                    this.thisSrc = n;
                }
                else {
                    this.thisSrc = this.placeholder;
                }
            },
            immediate: true
        },
        error(n) {
            if(n!==false) {
                setTimeout(()=>{this.error = false}, 3000);
            }
        }
    },
    methods: {
        uploadFile() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.accept = this.accept;
            this.$refs.fileInput.click();
            this.$refs.fileInput.removeEventListener("change", this._uploadFile);
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            const file = e.target.files[0];
            if(file && this.ownerId) {
                this.uploading = true;
                this.error = false;

                const formData = new FormData();
                formData.append("file", file, file.filename);

                this.axios.post(this.endPoint + "?ownerID=" + this.ownerId, formData).then(res => {
                    if (res.data.status == "uploaded") {
                        this.thisSrc = this.ApiEndpoint + res.data.filename;
                        this.$emit("input", res.data.filename);
                    }
                    else this.error = "Uploading failed!";
                }).catch(res => {
                    this.error = "Uploading failed!";
                    this.debug = res;
                }).finally(() => {
                    this.uploading = false;
                    e.target.value = null;
                });
            }
            else {
                this.error = "Missing Upload Parameter!";
            }
        }
    }
};
</script>