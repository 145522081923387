import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.use(VueAxios, axios);

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('pat-token');

    if (token) {
      config.headers['Authorization'] = `jwt ${ token }`;
    }

    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);

Vue.mixin({
  data: function() {
    return {
      get ApiEndpoint() {
        return "https://patconnect.com/api/";
      }
    }
  },
  methods: {
  },
})

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch("initializeStore");
  }
}).$mount('#app')
