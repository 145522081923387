import store from '../store'

export const authGuard = (to, from, next) => {
  //const authService = getInstance();
  
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('last-page', to.path);
    }
    
    if(store.state.user.isAuthenticated) {
      return next();
    }
    
    return next({ path: '/login' });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (store.state.user.loading===false) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  
  const check = () => {
    if (store.state.user.loading === false) {
      clearInterval(interval);
      return fn();
    }
  }
  let interval = setInterval(check, 150);
};