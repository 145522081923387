<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  }),
  watch: {
      '$route':{
        handler: (to) => {
          document.title = to.meta.title || 'PAT Software'
        },
        immediate: true
      }
    }
};
</script>
<style>
.v-dialog {
  border-radius: 0 !important;
}
.caption > a {
  text-decoration: none;
}
.caption {
  margin-top: -4px;
}
</style>
