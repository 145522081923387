import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    customProperties: true,
    theme: {
        themes: {
            light: {
                primary: '#008bd5',
                secondary: '#3494e5',
                anchor: '#0bb0ff',
                error: '#cd3838',
                pink: '#f8d7da',
            },
        },
        options: { minifyTheme },
    },
});
